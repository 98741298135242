import React, { useState } from 'react'

export const ProductsContext = React.createContext()

function ProductsProvider({ children }) {
  const [products, setProducts] = useState([])
  const [filtered, setFiltered] = useState([])
  return (
    <ProductsContext.Provider
      value={{
        products: [products, setProducts],
        filtered: [filtered, setFiltered],
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export default ProductsProvider
