import React from 'react';
import ThemeProvider from './theme';
import ProductsProvider from './products';
import UserProvider from './user';

// Implement any providers being used here
function Provider({ children }) {
  return (
    <ThemeProvider>
      <UserProvider>
        <ProductsProvider>{children}</ProductsProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default Provider;
