exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-champagne-jsx": () => import("./../../../src/pages/champagne.jsx" /* webpackChunkName: "component---src-pages-champagne-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-dessertvin-jsx": () => import("./../../../src/pages/dessertvin.jsx" /* webpackChunkName: "component---src-pages-dessertvin-jsx" */),
  "component---src-pages-hvidvin-jsx": () => import("./../../../src/pages/hvidvin.jsx" /* webpackChunkName: "component---src-pages-hvidvin-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-mousserende-jsx": () => import("./../../../src/pages/mousserende.jsx" /* webpackChunkName: "component---src-pages-mousserende-jsx" */),
  "component---src-pages-om-os-jsx": () => import("./../../../src/pages/om-os.jsx" /* webpackChunkName: "component---src-pages-om-os-jsx" */),
  "component---src-pages-portvin-jsx": () => import("./../../../src/pages/portvin.jsx" /* webpackChunkName: "component---src-pages-portvin-jsx" */),
  "component---src-pages-roedvin-jsx": () => import("./../../../src/pages/roedvin.jsx" /* webpackChunkName: "component---src-pages-roedvin-jsx" */),
  "component---src-pages-rose-jsx": () => import("./../../../src/pages/rose.jsx" /* webpackChunkName: "component---src-pages-rose-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/blogPostTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */)
}

