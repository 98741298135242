import React, { useState } from 'react';
import { ThemeProvider as Theme } from 'styled-components';

export const ThemeContext = React.createContext();

export const device = {
  smallMobile: `screen and (max-width: 324px)`,
  mobile: `screen and (max-width: 767px)`,
  tablet: `screen and (max-width: 1024px)`,
  desktop: `screen and (min-width: 1025px)`,
  monitor: `screen and (min-width: 1600px)`
};

const theme = {
  colors: {
    whiteWine: '#d4c52d',
    redWine: '#722f37',
    white: '#fff',
    black: '#000'
  },
  backgroundColors: {
    primary: '#722f37',
    secondary: '#1b2b38'
  },
  fontColors: {
    white: '#fff',
    black: '#000'
  },
  clipPath: {
    primary: 'polygon(0 0, 100% 0, 100% 86%, 0% 100%);'
  },
  space: {
    sectionSpace: '62px'
  }
};

const whiteWine = {
  ...theme,
  themeColor: {
    primary: '#d4c52d'
  }
};

const redWine = {
  ...theme,
  themeColor: {
    primary: '#722f37'
  }
};

const rose = {
  ...theme,
  themeColor: {
    primary: '#ee7272'
  }
};

const bubbles = {
  ...theme,
  themeColor: {
    primary: '#d4c52d'
  }
};

const dessertWine = {
  ...theme,
  themeColor: {
    primary: '#400000'
  }
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('redWine');
  const handleTheme = theme => {
    switch (theme) {
      case 'redWine':
        return redWine;
      case 'whiteWine':
        return whiteWine;
      case 'rose':
        return rose;
      case 'bubbles':
        return bubbles;
      case 'dessertWine':
        return dessertWine;
      default:
        return redWine;
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Theme theme={handleTheme(theme)}>{children}</Theme>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
