import React from 'react';
import Provider from './src/providers/provider';

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  //   console.log('new pathname', location.pathname);
  //   console.log('old pathname', prevLocation ? prevLocation.pathname : null);
};

export const wrapRootElement = ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
