import React, { useState } from 'react';
import { isBrowser } from '../utils/isBrowser';

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    if (isBrowser()) {
      const isLoggedIn = window.localStorage.getItem('user');
      if (isLoggedIn) {
        setUser(JSON.parse(isLoggedIn));
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
